body {
  background-color: #000;
  padding: 1%;
  color: #ccc;
  font-family: "Lucida Sans Typewriter", "Lucida Console", Monaco,
    "Bitstream Vera Sans Mono", monospace;
  font-size: 1em;
}

a {
  color: inherit;
}
